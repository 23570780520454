import { DataGrid, GridColumns, deDE } from '@mui/x-data-grid';
import { useMemo } from 'react';
import IConfig from '../../../models/config';
import { TWaterLevelTrends } from '../../../models/misc';
import { momentFullFormat } from '../../../service/chartDataHandler';
import '../../views/level-list/VLevelList.scss';
import './VWatertrend.scss';

interface IProps {
    config: IConfig;
    waterLevelTrends: TWaterLevelTrends;
    riverAreaId: number;
    riverAreaSlug: string;
}

interface IRowModel {
    number: number;
    name: string;
    riverName: string;
    timestamp: string;
    valueLastHour: string;
    valueLastHourBefore: string;
    trend: string;
    riverAreaSlug: string;
    slug: string;
}

const columns: GridColumns<IRowModel> = [
    {
        field: 'name',
        headerName: 'Pegel',
        flex: 1,
        sortComparator: (v1: string, v2: string) => v1.localeCompare(v2),
        renderCell: (params) => {
            const row = params.row;
            return row.riverAreaSlug && row.slug ? (
                <a href={`/flussgebiet/${row.riverAreaSlug}/${row.slug}`}>{row.name}</a>
            ) : (
                row.name
            );
        },
    },
    {
        field: 'riverName',
        headerName: 'Gewässer',
        flex: 1,
        sortComparator: (v1: string, v2: string) => v1.localeCompare(v2),
    },
    {
        field: 'timestamp',
        headerName: 'Zeitpunkt',
        flex: 1,
        minWidth: 160,
        sortComparator: (v1: string, v2: string) => v1.localeCompare(v2),
    },
    {
        field: 'valueLastHourBefore',
        headerName: 'Vorheriger Stundenwert',
        flex: 1,
        minWidth: 190,
        align: 'right',
        headerAlign: 'right',
        sortComparator: (v1: string, v2: string) => v1.localeCompare(v2),
    },
    {
        field: 'valueLastHour',
        headerName: 'Messwert',
        flex: 1,
        maxWidth: 100,
        align: 'right',
        headerAlign: 'right',
        sortComparator: (v1: string, v2: string) => v1.localeCompare(v2),
    },
    {
        field: 'trend',
        headerName: 'Differenz',
        flex: 1,
        maxWidth: 100,
        align: 'right',
        headerAlign: 'right',
        sortComparator: (v1: string, v2: string) => v1.localeCompare(v2),
    },
];

const VWatertrend = ({ config, waterLevelTrends, riverAreaId, riverAreaSlug }: IProps) => {
    const rows = useMemo(() => {
        const sortedMeasurementSites: number[] = config.measurementsitesorted[riverAreaId];
        const result: IRowModel[] = [];

        sortedMeasurementSites.forEach((msNumber) => {
            const waterLevelTrend = waterLevelTrends[msNumber];
            if (waterLevelTrend) {
                const msConfig = config.measurementsite[msNumber];
                const riverNumber = msConfig.rivers[0];
                const unit = msConfig.isSeaSite ? ' m ü. NHN' : ' cm';
                const unitTrend = msConfig.isSeaSite ? ' m' : ' cm';
                result.push({
                    number: msNumber,
                    name: msConfig.name,
                    riverName: config.rivers[riverNumber]?.name,
                    timestamp: waterLevelTrend.time ? momentFullFormat(waterLevelTrend.time) + ' Uhr' : '-',
                    valueLastHour: waterLevelTrend.value ? waterLevelTrend.value + unit : '-',
                    valueLastHourBefore: waterLevelTrend.valueBefore ? waterLevelTrend.valueBefore + unit : '-',
                    trend:
                        waterLevelTrend.trend !== null
                            ? (waterLevelTrend.trend > 0 ? '+' : '') +
                              waterLevelTrend.trend.toLocaleString() +
                              unitTrend
                            : '-',
                    riverAreaSlug,
                    slug: msConfig.slug,
                });
            }
        });

        return result;
    }, [config, riverAreaId, riverAreaSlug, waterLevelTrends]);

    return (
        <div className="v-level-list v-water-trend">
            <DataGrid
                rowHeight={32}
                headerHeight={32}
                rows={rows}
                columns={columns}
                disableColumnMenu
                autoHeight
                disableSelectionOnClick
                localeText={deDE.components.MuiDataGrid.defaultProps.localeText}
                hideFooterPagination={true}
                getRowId={(row) => row.number}
            />
        </div>
    );
};

export default VWatertrend;
