import { Box, Tab, Tabs } from '@material-ui/core';
import { ChangeEvent, ReactNode, useState } from 'react';
import IConfig from '../../../models/config';
import IIndex from '../../../models/index';
import { useConfigRequest } from '../../../service/globalService';
import './MDetailboxWaterlevels.scss';
import AsyncHelper from './MDetailboxWaterlevels__AsyncHelper';

interface ITabPanelProps {
    index: number;
    value: number;
    children: ReactNode;
}

const TabPanel = ({ children, value, index, ...other }: ITabPanelProps) => (
    <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
    >
        {value === index && (
            <Box className="m-detailbox-waterlevels__tab-box" p={3}>
                {children}
            </Box>
        )}
    </div>
);

interface IProps {
    index?: IIndex;
    config: IConfig;
}

const MDetailboxWaterlevels = ({ index }: IProps) => {
    const [tabValue, setTabValue] = useState<number>(0);
    const changeActiveTab = (event: ChangeEvent, newValue: number) => {
        setTabValue(newValue);
    };

    const { data: config } = useConfigRequest();

    return (
        <div className="m-detailbox-waterlevels">
            <Box className="m-detailbox-waterlevels__navigation">
                <Tabs
                    value={tabValue}
                    onChange={changeActiveTab}
                    aria-label="m-detailbox-waterlevels__tabs"
                    variant="scrollable"
                    scrollButtons={'on'}
                >
                    {Object.entries(config.riverareas).map(([raId, riverArea]) =>
                        // Skip municipal river area.
                        raId !== '8888888888' ? (
                            <Tab label={riverArea.name} key={raId} className="m-detailbox-waterlevels__tab-button" />
                        ) : null
                    )}
                </Tabs>
            </Box>

            {Object.keys(config.riverareas).map((raId, i) => (
                <TabPanel value={tabValue} index={i} key={raId}>
                    <AsyncHelper index={index} config={config} riverAreaId={parseInt(raId)} />
                </TabPanel>
            ))}
        </div>
    );
};
export default MDetailboxWaterlevels;
